import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import SearchCode from './views/SearchCode';
import SearchView from './views/SearchView';
import ConfirmView from './views/ConfirmView';
import TurnView from './views/TurnView';
import SelectCompany from './views/SelectCompany';
import Queues from './views/Queues';
import OfflineView from './views/OfflineView';
import api from './requests/api';
import './styles/scss/main.scss';
import 'react-notifications/lib/notifications.css';
import './requests/interceptors';
import { AppConsumer } from "./components/AppContext";
import { NotificationContainer } from "react-notifications";
import RouteCatchError from "./components/ErrorBoundary/RouteCatchError";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import 'font-awesome/css/font-awesome.min.css';
import MenuBar from "./components/MenuBar";
import Profile from "./views/Profile";
import BranchesWithProfile from "./views/BranchesWithProfile";
import VideocallWithTurnView from "./views/VideocallWithTurnView";
import NotFound from "./views/NotFound";
import {i18nEvents} from './i18n';
import Alert from './components/Alert';
import { Route } from 'react-router-dom';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            hasFileUpdates: false
        };
        const { ctx } = this.props;
        const parts = this.props.location.pathname.split('/');
        if (['buscar','buscarturno','b'].includes(parts[1]) && (parts.length === 3 || parts.length === 4)) {
            ctx.actions.selectCompany(parts[2], null);
        } else {
            if(ctx.state.selectedCompany === ""){
                this.props.history.push('/empresa');
            }
        }
        // Forces update when translations are loaded
        i18nEvents.onTranslationsLoaded(_=> this.forceUpdate());

        // Checks for updates on other files in SW
        document.addEventListener('filesUpdated', e => {
            this.setState({hasFileUpdates: true});
            this.swRegistration = e.detail.swRegistration;
        });
    }

    goHome = ()=>{
        const { ctx } = this.props;
        if (ctx.state.selectedCompany === '') {
            this.props.history.push('/empresa')
        } else {
            this.props.history.push('/buscar/' + ctx.state.selectedCompany);
        }
    };

    goToEditProfileView = () => {
        this.props.history.push('/profile');
    };

    /**
     * Check if the user is logged in
     */
    isAuth(){
        return api.auth().isAuth().then(response => {
            const { ctx } = this.props;
            ctx.actions.setAuth(true);
            return response;
        }).catch(err => {
            const { ctx } = this.props;
            ctx.actions.setAuth(false);
        });
    }


    updateSW() {
        const registrationWaiting = this.swRegistration;
        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
            registrationWaiting.addEventListener('statechange', e => {
                console.log("Unregistered SW", e);
                window.location.reload(true);
            });
            // Fixes bug where statechange cb is not called
            setTimeout(_=>window.location.reload(true), 300);
        } else {
            console.log("No SW to unregister");
            window.location.reload(true);
        }
    }


    render() {
        const { ctx } = this.props;
        const { t } = ctx;

        document.title = ctx.t("Index.TITLE");

        const path = this.props.location.pathname.split('/');
        if (path[1] === "empresa" && ctx.state.selectedCompany !== '') {
            ctx.actions.selectCompany(null);
        }
        const css = this.props.ctx.state.customCss;

        return (
            <ErrorBoundary>
                <div className="main-container">
                    {this.state.hasFileUpdates &&
                        <Alert text={t("App.UPDATES_FOUND")}
                            buttonText={t("App.UPDATE")}
                            onClick={ _ =>this.updateSW() }
                            onTimeoutClose={ _=>this.setState({hasFileUpdates: false}) }/>
                    }
                    <style>
                    {/* Custom Styles */}
                    {css}
                    </style>
                    <OfflineView />
                    <Switch>
                        <Route exact path="/" component={SelectCompany} />
                        <RouteCatchError exact path="/profile" component={Profile} />
                        <RouteCatchError exact path="/buscarturno" component={SearchView} />
                        <RouteCatchError exact path="/buscarturno/:companyName+" component={SearchView} />
                        <RouteCatchError exact path="/b" component={SearchView} />
                        <RouteCatchError exact path="/b/:companyName+" component={SearchView} />
                        <RouteCatchError exact path="/buscar/:name?" component={SearchCode} />
                        <RouteCatchError exact path="/empresa" component={SelectCompany} />
                        <RouteCatchError exact path="/tramites" component={Queues} />
                        <RouteCatchError exact path="/sucursales" component={BranchesWithProfile} />
                        <RouteCatchError exact path="/confirmar" component={ConfirmView} />
                        <RouteCatchError exact path="/turno" render={(props) => <TurnView {...props} turn={this.props.ctx.state.actualTurn}/> }/>
                        <RouteCatchError exact path="/videocall" component={VideocallWithTurnView} />
                        <Route exact path="/error" component={NotFound} />
                        <Route component={NotFound} />
                    </Switch>
                    {
                        path[1] !== "videocall" && path[1] !== "turno" &&
                        <MenuBar
                            actions={[
                                this.goHome,
                                this.goToEditProfileView
                            ]}
                            icons={[
                                "home",
                                "user"
                            ]}
                        />
                    }

                    <NotificationContainer />

                </div>
            </ErrorBoundary>
        );
    }
}

/**
 * Just for access to the method and values of the context
 */
const AppRef = React.forwardRef((props, ref) => (
    <AppConsumer>
        {ctx => <App {...props} ctx={ctx} ref={ref} />}
    </AppConsumer>
));

/**
 * For access to the history
 */
export default withRouter(AppRef);
