import React from 'react';
import './ErrorBoundary.scss';
import { AppConsumer } from '../AppContext';

import img_error from '../../img/img_error.png';
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    componentDidCatch(error, info) {
        console.log({error, info});

        this.setState({ hasError: true, error: error, errorInfo: info });
    }

    reload() {
        this.setState({
            hasError: false,
            error: null,
            errorInfo: null,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <AppConsumer>
                {({t}) =>
                <div className="errorView">
                    <img alt={t("ErrorBoundary.ERROR_IMAGE")} src={img_error} className={'img-responsive'} />
                    <div className={'errorView__texts'}>
                        {t("ErrorBoundary.ERROR_UPLOAD")}
                        {process.env.NODE_ENV !== 'production' && (
                            <div className={"error-info--item__description"} style={{ marginTop: '10px' }}>
                                <details style={{ whiteSpace: 'pre-wrap' }}>
                                    {this.state.errorInfo.componentStack}
                                </details>
                                <details style={{ whiteSpace: 'pre-wrap' }}>{this.state.error.stack}</details>
                            </div>
                        )}
                    </div>
                    <div className={'error-info--item__button'}>
                        <button
                            onClick={this.reload.bind(this)}
                            className="button button-calm button-block">
                            {t("ErrorBoundary.LOAD_AGAIN")}
                        </button>
                    </div>
                </div>
                }
                </AppConsumer>
            );
        }
        return this.props.children;
    }
}
