import React from 'react';
import PropTypes from 'prop-types';

export default class MenuBar extends React.Component {
    renderIcons () {
        return this.props.icons.map((j, pos)=>{
            return (
                <li key={j}>
                    <button aria-label={j}>
                    <i className={`fa fa-${j} fa-2x`} onClick={this.props.actions[pos]} />
                    </button>
                </li>
            )
        })
    }
    render() {
        return (
            <div className="menu-bar">
                <ul className="menu-bar__container-items">
                    {this.renderIcons()}
                </ul>
            </div>
        )
    }

}

MenuBar.propTypes = {
    icons: PropTypes.arrayOf(PropTypes.string),
    actions: PropTypes.arrayOf(PropTypes.func)
};

MenuBar.defaultProps = {
    icons: [
        "home",
        "user-circle",
        "clock-o"],
    actions: []
};
