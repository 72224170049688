import React, { Component } from 'react';
import { isEmpty } from '../helpers.js';
import img from '../img/tramites_img.svg';
import { AppConsumer } from '../components/AppContext';
import Loader from '../components/Loader';
import { NotificationManager } from "react-notifications";
import TopBar from "../components/TopBar";
import ModalDialog from "../components/ModalDialog";
import withTracker from "../utils/withTracker";
import TrackedButton from "../components/TrackedButton";
import * as Analytics from '../utils/Analytics'

class Queues extends Component {
    constructor(props) {
        super(props);
        this.selectQueue = this.selectQueue.bind(this);
        this.toggleConfirmDialog = this.toggleConfirmDialog.bind(this);
        this.state = {
            textValue: "",
            queues: [],
            selectedQueue: {},
            branches: [],
            preSelectedBranch: {},
            loading: true,
            titleFilter: '',
            showConfirmDialog: false,
            hasActiveTurns: false
        };
    }

    toggleConfirmDialog(showDialog) {
        this.setState({showConfirmDialog: showDialog});
    }

    /**
     * Get the list of the available queues
     * @returns {Promise}
     */
    getQueues() {
        return new Promise(((resolve, reject) => {
            const {actions: {getQueues}} = this.context;
            getQueues().then(resolve, resolve.bind(this, undefined));
        }));
    }
     /**
     * Get the list of the available queues for specific schedule
     * @returns {Promise}
     */
    getAppointmentsQueues() {
        return new Promise(((resolve, reject) => {
            const {actions: {getAppointmentsQueues}} = this.context;
            getAppointmentsQueues().then(resolve, resolve.bind(this, undefined));
        }));
    }

    /**
     * Set a queue in the selectedQueue state
     * @param {Object} selected
     */
    selectQueue(selected, inputId) {
        if(this.state.hasActiveTurns && selected.isAppointment) {
            const { t } = this.context;
            NotificationManager.error(t("Queues.CHECKIN_NOT_ALLOWED"));
            if(inputId)
                setTimeout(_=>
                    document.getElementById(inputId).checked = false
                , 0);
            return;
        }
        this.setState({ selectedQueue: selected });
    }


    async componentDidMount() {
        const { state, t } = this.context;
        if (state.actualTurnStatus === "FINALIZED") {
            NotificationManager.warning(t("Queues.FINISH_GET_NEW"));
            this.props.history.push('/turno');
        }
        if (this.context.state.profileList === undefined) {
            this.context.actions.createProfileList();
        }
        this.context.actions.checkProfileByCompany();
        if ((state.actualTurn.code && !state.actualTurn.finishTime) && state.isAppointment === false) {
            NotificationManager.warning(t("Queues.CANCEL_BEFORE"));
            if(state.actualTurn.videoCallUrl && state.actualTurn.videoCallUrl !== "") {
                this.props.history.push('/videocall');
            } else {
                this.props.history.push('/turno');
            }
        }
        // Flujo normal de la app
        if (state.isAppointment === false){
            const queues = await this.getQueues();
            if (Object.keys(this.context.state.preSelectedBranch).length === 0 && (this.context.state.preSelectedBranch.constructor === Object)) {
                // Si no viene con sucursal Preseleccioanda
                this.setState({ textValue: "Queues.GOTO_BRANCHES" });
                if(queues === undefined){
                    NotificationManager.error(t("Queues.SELECT_COMPANY_AGAIN"));
                    this.props.history.push('/buscar/' + this.context.state.selectedCompany);
                }else{
                    this.setState({ queues: queues, loading: false });
                }
            } else {
                // Si viene con sucursal Preseleccioanda
                this.setState({ textValue: "Queues.CONTINUE", preSelectedBranch: this.context.state.preSelectedBranch });
                // Filtrar queues segun branch
                   const filterQueues = [];
                   queues.forEach(queue => {
                      for (let i = 0; i < queue.branches.length; i++) {
                          if (queue.branches[i].id === this.context.state.preSelectedBranch.id){
                              // Cambio el id de la queue
                              queue.id = queue.branches[i].queueId;
                              queue.branchLabel = queue.branches[i].label;
                              filterQueues.push(queue);
                          }
                      }
                  });
                  this.setState({ queues: filterQueues, loading: false });
                  // Si solo existe un tramite
                  if (this.state.queues.length === 1 && !this.context.state.preSelectedBranch.preSelecetdQueue){
                    this.context.actions.saveQueueList(this.state.queues, this.context.actions.goToConfirm.bind(this, filterQueues[0]));
                  }
                  // Si viene con tramite pre seleccionado
                  if (this.context.state.preSelectedBranch.preSelecetdQueue) {
                    this.setState({ loading: true });
                    const selectedQueue = filterQueues.filter(queue => {
                        return queue.name === this.context.state.preSelectedBranch.preSelecetdQueue
                    });
                    this.context.actions.saveQueueList(this.state.queues, this.context.actions.goToConfirm.bind(this, selectedQueue[0]));
                  }
            }
        }  else {
            // Flujo Check In (Citas)
            // Si se hace checkin por dato de customer
            if(this.context.state.appointmentData === "clientCheckIn"){
                const customers = this.context.state.appointmentClientData;
                const queueList = [];
                let hasActiveTurns = false;

                customers.forEach(function (customer) {
                    if(customer.appointments) {
                        customer.appointments.forEach(function(appointment) {
                            const startDate = new Date(appointment.checkInMin);
                            const endDate = new Date(appointment.checkInMax);
                            const now = new Date();
                            if(appointment.buttons && (startDate < now && now < endDate && startDate < endDate)){
                                appointment.buttons.forEach(function (button) {
                                    let queueToAdd = {
                                        'id': button['queue.id'],
                                        'name': button.name,
                                        'appointmentCode': button.appointmentCode,
                                        'schedule.id': button['schedule.id'],
                                        'clientId': customer.id,
                                        'virtualAttention': button['queue.virtualAttention'],
                                        'firstName': customer.firstName,
                                        'lastName': customer.lastName,
                                        'dni': customer.dni,
                                        'cuil': customer.cuil,
                                        'cuit': customer.cuit,
                                        'branch': button.branch,
                                        'branches': [button.branch],
                                        'isAppointment': true
                                    }
                                    queueList.push(queueToAdd);
                                });
                            }
                        });
                    }
                    if(customer.turns && customer.turns.length) {
                        hasActiveTurns = true;
                        customer.turns.forEach(function(turn) {
                            let queueToAdd = {
                                'id': turn.id,
                                'name': turn.queue.name,
                                'turnCode': turn.code,
                                'clientId': customer.id,
                                'virtualAttention': turn.queue.virtualAttention,
                                'firstName': customer.firstName,
                                'lastName': customer.lastName,
                                'dni': customer.dni,
                                'cuil': customer.cuil,
                                'cuit': customer.cuit,
                                'branch': turn.branch,
                                'branches': [turn.branch],
                                'isAppointment': false,
                                'turn': turn
                            }
                            queueList.push(queueToAdd);
                        });
                    }
                });
                if(queueList.length === 0) {
                     NotificationManager.error(t("Queues.OUT_OF_TIME"));
                     this.props.history.push('/buscarturno');
                }
                this.setState({ queues: queueList, loading: false, hasActiveTurns });
                if (queueList.length === 1) {
                    if(!queueList[0].isAppointment){
                        NotificationManager.success(t("Queues.ACTIVE_TURN"));
                        this.context.actions.setActualTurn(queueList[0]);
                        this.props.history.push('/turno');
                    } else {
                        Analytics.event("auto_select_queue");
                        this.context.actions.saveQueueList(this.state.queues, this.context.actions.goToConfirm.bind(this, queueList[0]));
                    }
                }

            } else {
                // Si es un checkIn por código de cita
                const queues = await this.getAppointmentsQueues();
                this.setState({ queues: queues, loading: false });
                 if (this.state.queues.length === 1){
                     this.context.actions.saveQueueList(this.state.queues, this.context.actions.goToConfirm.bind(this, queues[0]));
                 }
            }
        }
    }

    saveQueuesListAndQueueSelection = (actions) => {
        const { state, t } = this.context;
        if (Object.keys(this.state.preSelectedBranch).length === 0 && (this.state.preSelectedBranch.constructor === Object)) {
            if (actions && state.isAppointment === true) {
                if(this.state.selectedQueue.isAppointment === true) {
                    actions.saveQueueList(this.state.queues, actions.goToConfirm.bind(this, this.state.selectedQueue));
                } else {
                    if(this.context.state.appointmentData !== "clientCheckIn") {
                        actions.saveQueueList(this.state.queues, actions.goToConfirm.bind(this, this.state.selectedQueue));
                    } else {
                        NotificationManager.success(t("Queues.ACTIVE_TURN"));
                        actions.setActualTurn(this.state.selectedQueue);
                        this.props.history.push('/turno');
                    }
                }
            } else if (actions && state.isAppointment === false) {
                actions.saveQueueList(this.state.queues, actions.selectQueue.bind(this, this.state.selectedQueue));
            }
        } else {
            actions.saveQueueList(this.state.queues, actions.goToConfirm.bind(this, this.state.selectedQueue));
        }

    }

    confirmQueueSelection(actions, e) {
        e.preventDefault();

        if(!this.state.selectedQueue.virtualAttention && actions)
            this.saveQueuesListAndQueueSelection(actions);
        else
            this.toggleConfirmDialog(true);
    }

    render() {
        const { queues, selectedQueue, loading } = this.state;
        const showName = queues.some(q1=> {
            return queues[0].clientId !== q1.clientId;
        });

        return (
            <Loader loading={loading} fullscreen={false}>
                <div className="viewSelectQueue">
                {this.state.preSelectedBranch.id === undefined ?
                    <TopBar
                        linkBack={"/buscar/" + this.context.state.selectedCompany}
                        title={this.context.state.selectedCompany}
                    />
                                        :
                    <TopBar
                        linkBack={"/buscar/" + this.context.state.selectedCompany + "?branchId=" + this.state.preSelectedBranch.id}
                        title={this.context.state.selectedCompany}
                    />
                    }
                    <AppConsumer>
                        {({actions, t}) => (
                            <div className="container-body content">
                                <div className="card card-info">
                                    <form
                                        onSubmit={this.confirmQueueSelection.bind(this, actions)}
                                        className="">
                                        <div className="viewSelectQueue__imgContainer">
                                            <img
                                                src={img}
                                                alt={t("Queues.QUEUE_IMAGE")}
                                                className="img-responsive viewSelectQueue__img viewSelectQueue__maxImg"
                                            />
                                            <h2 className="deb-main-title viewSelectQueue__title"> { t("Queues.SELECT_QUEUE") } </h2>
                                        </div>
                                        <div className="viewSelectQueue__list">
                                            <div className="custom-radio-selector">
                                                <span className={"viewSelectQueue__search-bar"}>
                                                    <i className={`fa fa-search`} />
                                                    <input type="text" name={"filter"} onChange={this.handleFilterChange} placeholder={t("Queues.FIND_QUEUE")}/>
                                                </span>
                                                {queues.map((queue, i) => {
                                                    const inputId = queue.clientId === undefined ? queue.id : queue.id+'-'+queue.clientId+'-'+i;
                                                    return (
                                                        <div key={i}
                                                             className={`inputGroup ${(this.titleFilterMatches(queue.name) || this.state.selectedQueue === queue) ? '' : `inputGroup-hidden`}`}>
                                                            <input id={inputId} type="radio" name="turn"
                                                                   value={queue.clientId === undefined ? queue.id : queue.id+'-'+queue.clientId+'-'+i}/>
                                                            <div className='viewSelectBranch__branch-title'
                                                                 onClick={this.selectQueue.bind(this, queue, inputId)}>
                                                                <label htmlFor={queue.clientId === undefined ? queue.id : queue.id+'-'+queue.clientId+'-'+i}>
                                                                    {
                                                                        this.context.state.isAppointment === true && (this.context.state.appointmentData === "clientCheckIn" || this.context.state.appointmentCode === "") &&
                                                                        <div className='viewSelectBranch__queueOrAppoitment'>
                                                                        {
                                                                            queue.isAppointment ? (
                                                                                <span>{t("Queues.APPOINTMENT")}</span>
                                                                            )
                                                                            :
                                                                            (
                                                                                <span>{t("Queues.TURN")}</span>
                                                                            )
                                                                        }
                                                                        </div>
                                                                    }
                                                                    {(queue.clientId === undefined || !showName) ?
                                                                        queue.name + " " + (queue.appointmentCode || queue.turnCode || "") :
                                                                        queue.name + " " + ( queue.firstName||'' ) + " " + ( queue.lastName||'' )}

                                                                    {
                                                                        queue.virtualAttention &&
                                                                        <i className="virtual-attention icon ion-ios-videocam-outline"></i>
                                                                    }
                                                                </label>
                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                            </div>
                                        </div>
                                        <div className="row">
                                        {this.context.state.isAppointment === false ?
                                            <TrackedButton
                                                id="select_queue"
                                                className="button button-calm button-block button-primary button-primary--fixed"
                                                disabled={isEmpty(selectedQueue) ? 'disabled' : ''}>
                                                {t(this.state.textValue)}
                                            </TrackedButton>
                                        :
                                        <TrackedButton
                                                id="select_queue"
                                                className="button button-calm button-block button-primary button-primary--fixed"
                                                disabled={isEmpty(selectedQueue) ? 'disabled' : ''}>
                                            { t("Queues.CONFIRM_CHECKIN") }
                                            </TrackedButton>
                                        }
                                        </div>
                                    </form>
                                </div>
                                {
                                    this.state.showConfirmDialog &&
                                    <ModalDialog
                                        title={t("Queues.TITLE")}
                                        description={t("Queues.DESCRIPTION")}
                                        onConfirm={()=>{
                                            this.toggleConfirmDialog(false);
                                            this.saveQueuesListAndQueueSelection(actions);}}
                                        onCancel={()=>{this.toggleConfirmDialog(false)}}
                                        type={"confirm"}
                                        primaryLabel={t("Queues.ACEPT")}
                                        secondaryLabel={t("Queues.CANCEL")}
                                    />
                                }
                            </div>
                        )}
                    </AppConsumer>
                </div>
            </Loader>
        );
    }

    handleFilterChange = (ev) => {
        this.setState({titleFilter: ev.target.value});
    };

    titleFilterMatches = (name) => {
        const lowerCaseNormalizedName = name.toLocaleLowerCase().normalize('NFKD');
        const lowerCaseNormalizedFilter = this.state.titleFilter.toLocaleLowerCase().normalize('NFKD');

        // remove code points which make composite characters
        return lowerCaseNormalizedName.replace(/[^\w\s.-/\\]/g, '')
            .indexOf(lowerCaseNormalizedFilter.replace(/[^\w\s.-/\\]/g, '')) !== -1;
    };
}
Queues.contextType = AppConsumer;
export default withTracker(Queues);
