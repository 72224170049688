import React from 'react';
import { withRouter } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import api from '../requests/api';
import * as LocalNotifications from '../utils/LocalNotifications';
import { Translation } from 'react-i18next';
import * as Analytics from '../utils/Analytics';
import { encryptCustomer } from '../utils/Encryption.js';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

export const AppContext = React.createContext({});
const stateKey = 'debQMobile';

export const AppConsumer = AppContext.Consumer;

class Context extends React.Component {
    constructor(props) {
        super(props);
        this.state = JSON.parse(
            localStorage.getItem(stateKey) ||
            JSON.stringify({
                isAuth: false,
                selectedCompany: '',
                selectedQueue: {},
                selectedBranch: {},
                preSelectedBranch: {},
                profile: {},
                profileList: {},
                turnCode: '',
                appointmentTurn: '',
                actualTurn: {},
                queues: [],
                branches: [],
                appointmentData: {},
            })
        );
        this.updateState = this.updateState.bind(this);
        this.setAuth = this.setAuth.bind(this);
        this.setActualTurn = this.setActualTurn.bind(this);
        this.setAppointmentTurn = this.setAppointmentTurn.bind(this);
        this.cancelTurn = this.cancelTurn.bind(this);
        this.login = this.login.bind(this);
        this.signup = this.signup.bind(this);
        this.selectCompany = this.selectCompany.bind(this);
        this.goToSearchCodeView = this.goToSearchCodeView.bind(this);
        this.insertCustomCss = this.insertCustomCss.bind(this);
        this.insertCheckIfCaptchaAvailable = this.insertCheckIfCaptchaAvailable.bind(this);
        this.saveAppointmentClientData = this.saveAppointmentClientData.bind(this);
        this.goToConfirm = this.goToConfirm.bind(this);
        this.cleanPreSelect = this.cleanPreSelect.bind(this);
        this.selectQueue = this.selectQueue.bind(this);
        this.saveQueueList = this.saveQueueList.bind(this);
        this.saveBranchList = this.saveBranchList.bind(this);
        this.selectBranch = this.selectBranch.bind(this);
        this.preSelectBranch = this.preSelectBranch.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
        this.getTurn = this.getTurn.bind(this);
        this.setTurnStatus = this.setTurnStatus.bind(this);
        this.getCompanyById = this.getCompanyById.bind(this);
        this.getBranches = this.getBranches.bind(this);
        this.profilesList = this.profilesList.bind(this);
        this.checkProfileByCompany = this.checkProfileByCompany.bind(this);
        this.createProfileList = this.createProfileList.bind(this);
        this.getQueues = this.getQueues.bind(this);
        this.cleanQueues = this.cleanQueues.bind(this);
        this.preSelectBranchOrQueue = this.preSelectBranchOrQueue.bind(this);
        this.getAppointmentsQueues = this.getAppointmentsQueues.bind(this);
        this.checkTurnStatus = this.checkTurnStatus.bind(this);
        this.checkIsAppointments = this.checkIsAppointments.bind(this);
        this.setCodeCheckIn = this.setCodeCheckIn.bind(this);
        this.checkInConfirmed = this.checkInConfirmed.bind(this);
        this.resetActualTurn = this.resetActualTurn.bind(this);

    }

    /**
     * Set a state and save it in the local storage
     * @param newState
     * @param callback
     */
    updateState(newState, callback) {
        this.setState(newState, () => {
            localStorage.setItem(stateKey, JSON.stringify(this.state));
            if (typeof callback === 'function') {
                callback(this.state);
            }
        });
    }

    /**
     * Update the Auth state
     * @param isAuth
     */
    setAuth(isAuth) {
        this.updateState({ isAuth });
    }

    checkTurnStatus() {
        const { status: turnStatus } = this.state.actualTurn;
        const { notified, turnCode } = this.state;
        if (!turnStatus) {
            return;
        }
        if (turnStatus === "CALLED" || turnStatus === "ANNOUNCED") {
            if (notified !== turnCode) {
                LocalNotifications.info(i18next.t("AppContext.CALLING_YOU"),
                i18next.t("AppContext.YOUR_TURN"), 3000, undefined, undefined, turnCode);
                this.updateState({ notified: turnCode });
            }
        } else if (turnStatus === "WAITING_TO_BE_CALLED" || turnStatus === "ABSENT") {
            if (notified === turnCode) {
                this.updateState({ notified: undefined });
            }
        }
    }

    /**
     * Change the selectedBranch, queue, turnCode and actualTurn state
     * @param actualTurn
     */
    setActualTurn(actualTurn) {

        // Trasnformo el objeto para el resto de la logica ya que la misma funcion va para 2 flujos
        if(actualTurn.turn !== undefined) {
            actualTurn.jsonDetails = actualTurn.turn
            actualTurn.branch = actualTurn.turn.branch
            actualTurn.code = actualTurn.turn.code
            delete actualTurn.turn
        }

        const getCurrentBranchForTurn = () => {
            return this.state.branches.filter((br) => {
                if (actualTurn.branch) {
                    return br.id === actualTurn.branch.id;
                }
                return false;
            })[0];
        };
        const getCurrentQueueForTurn = () => {
            const getIdsForQueue = (branchList) => {
                return branchList.map((br) => {
                    return typeof br.queueId === "number" ? br.queueId : false;
                }).filter(_ => _ !== false);
            }
            return this.state.queues.filter((q) => {
                if (actualTurn.jsonDetails && actualTurn.jsonDetails.queue) {
                    return getIdsForQueue(q.branches).includes(actualTurn.jsonDetails.queue.id);
                }
                return false;
            })[0];
        };

        let branch = getCurrentBranchForTurn();
        if (!branch) branch = this.state.selectedBranch;

        let queue = getCurrentQueueForTurn();
        if (!queue){
            if(actualTurn.jsonDetails.queue){
                queue = actualTurn.jsonDetails.queue;
            }else{
                queue = this.state.selectedQueue;
            }
        }

        this.updateState({ actualTurn, turnCode: actualTurn.code, selectedBranch: branch, selectedQueue: queue, isAppointment: false }, this.checkTurnStatus);

        const saveBranches = (branches) => {
            return new Promise((resolve, reject) => {
                let branch = getCurrentBranchForTurn();
                if (!branch) branch = this.state.selectedBranch;
                this.updateState({ branches: branches, selectedBranch: branch }, resolve);
            });
        };
        const saveQueues = (queues) => {
            return new Promise((resolve, reject) => {
                let queue = getCurrentQueueForTurn();
                if (!queue) queue = this.state.selectedQueue;
                this.updateState({ queues: queues, selectedQueue: queue }, resolve);
            });
        };

        if (this.state.queues.length === 0) {
            this.getQueues().then(saveQueues).then(this.getBranches).then(saveBranches);
        } else if (this.state.branches.length === 0) {
            this.getBranches().then(saveBranches);
        }
    }

    setAppointmentTurn(data) {
        if (!this.state.checkInConfirmed) {
            // Trasnformo el objeto para el resto de la logica ya que la misma funcion va para 3 flujos
            let mBranchId;
            if(this.state.appointmentData === "clientCheckIn"){
                data.appointment = {};
                data.appointment.branch = {};
                data.appointment.branch.id = data.branch.id;
                data.appointment.branch.name = data.branch.name;
                data.appointment.branch.label = data.branch.label
                mBranchId = data.appointment.branch.id;
            } else {
                mBranchId = data.appointment.mBranchId;
            };
            let branch = { id: mBranchId, name: data.appointment.branch.name, label: data.appointment.branch.label };
            let branches = [branch];
            let appointmentClientData = {};
            if(data.customer) {
                appointmentClientData.firstName = data.customer.firstName;
                appointmentClientData.lastName = data.customer.lastName;
                appointmentClientData.email = data.customer.email;
                appointmentClientData.phone = data.customer.phone;
                appointmentClientData.dni = data.customer.dni;
                appointmentClientData.cuil = data.customer.cuil;
                appointmentClientData.cuit = data.customer.cuit;
                appointmentClientData.externalId = data.customer.externalId;
                appointmentClientData.extraFields = data.customer.extraFields;
            } else {
                appointmentClientData.firstName = data.firstName;
                appointmentClientData.lastName = data.lastName;
                appointmentClientData.email = data.email;
                appointmentClientData.phone = data.phone;
                appointmentClientData.dni = data.dni;
                appointmentClientData.cuil = data.cuil;
                appointmentClientData.cuit = data.cuit;
                appointmentClientData.externalId = data.externalId;
                appointmentClientData.extraFields = data.extraFields;
            }
            this.updateState({
                appointmentData: data,
                appointmentCode: data.appointment.code || data.code,
                selectedBranch: branch,
                branches: branches,
                schedule: data.appointment.schedule,
                isAppointment: true,
                appointmentClientData: appointmentClientData,
                mobileBranch: mBranchId,
            }, this.checkTurnStatus);
        } else {
            this.updateState({ appointmentData: data, appointmentCode: data.code, isAppointment: true }, this.checkTurnStatus)
        }
    }

    checkIsAppointments() {
        this.updateState({ isAppointment: false }, this.checkTurnStatus);
    }

    setCodeCheckIn() {
        this.updateState({ appointmentData: "codeCheckIn" }, this.checkTurnStatus);
    }

    checkInConfirmed(value) {
        this.updateState({ checkInConfirmed: value }, this.checkTurnStatus);
    }

    saveAppointmentClientData(client) {
        this.updateState({ appointmentClientData: client, isAppointment: true, appointmentCode: "", appointmentData: "clientCheckIn", mobileBranch: {}, profile: {}, selectedBranch: {}, preSelectedBranch: {}, schedule: {}, queues: [], branches: [] }, this.checkTurnStatus);
    }

    cleanQueues() {
        this.updateState({ queues: [], selectedQueue: {}, selectedBranch:{}}, this.checkTurnStatus);
    }

    preSelectBranchOrQueue(data){
        this.cleanQueues();
        const branchId = data.branchId;
        const queueName = data.queueName;
        const branchName = data.branchName;
        const error = () => {
            NotificationManager.error(i18next.t("AppContext.ERROR_COMPANY"));
            this.props.history.push('/buscar/' + this.state.selectedCompany);
        }

        const searchQueueAndBranch = (branch, queue) => {
            this.getBranches()
            .then(req => {
                if(req === undefined){
                    error();
                }else{
                    const branchList = req;
                    req.forEach(br => {
                        if(br.id === branch || br.name === branch || br.label === branch){
                            if (queue) br.preSelecetdQueue = queue;
                            this.updateState({preSelectedBranch: br, branches: branchList, isAppointment: false}, this.checkTurnStatus);
                            this.props.history.push('/tramites');
                        }
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                error();
            });
        };

        const searchQueue = (queueName) => {
             this.getQueues()
             .then(req => {
                 if(req === undefined){
                     error();
                }else{
                    req.forEach(queue => {
                        if (queue.name === queueName) {
                            queue.preSelecetd = true;
                            this.saveQueueList(req, this.selectQueue.bind(this, queue));
                        }
                    });
                }
             })
             .catch((err) => {
                 console.error(err);
                 error();
             });
        };

        if (queueName && !(branchId || branchName)) {
            searchQueue(queueName);
        } else if(branchId || branchName) {
            searchQueueAndBranch((branchId || branchName), queueName)
        }

    }

    getTurn() {
        return new Promise(async (resolve, reject) => {
            const queueId = this.state.selectedBranch.queueId || this.state.selectedQueue.id;
            const branchId = this.state.selectedBranch.id || this.state.preSelectedBranch.id;
            const pubkey = (await api.customers().getPublicKey()).data;
            let getTurn = (queueId, branchId, body) => {
                body = encryptCustomer(body, pubkey);
                api.turn()
                .new(queueId, branchId, body)
                .then(res => {
                    this.updateState(
                        {
                            actualTurn: res.data,
                            turnCode: res.data.code,
                            isAppointment: false,
                            appointmentCode: '',
                            appointmentData: {}

                        },
                        () => {
                            resolve(res.data);
                        }
                    );
                })
                .catch((err) => {
                    console.error(err);
                    NotificationManager.error(i18next.t("AppContext.COULD_NOT_GET"));
                    reject(err);
                });
            }

            // Agrego el schudle id y el appointmentCode solo si es para checkIn
            if (this.state.isAppointment) {
                const body = Object.assign({}, this.state.appointmentClientData);
                body.appointmentCode = this.state.appointmentCode;
                body.clientId = body.externalId;
                if (this.state.appointmentData.schedule) {
                    body.schedule = {
                        id: this.state.appointmentData.schedule.qScheduleId
                    };
                } else if (this.state.appointmentData.appointment.schedule){
                    body.schedule = {
                        cloudId: this.state.appointmentData.appointment.schedule.id,
                    };
                }
                body.extraFields = this.state.appointmentData.extraFields;
                delete body.externalId;
                getTurn(queueId, branchId, body);
            } else {
                let cleanObj = (obj) => {
                    for (var propName in obj) {
                        if (!obj[propName] || obj[propName] === "") {
                          delete obj[propName];
                        }
                      }
                      return obj
                };
                let customerToSearch = cleanObj(this.state.profile);
                const encryptedCustomerToSearch = encryptCustomer(customerToSearch, pubkey);

                api.customers().getReducedInfo(encryptedCustomerToSearch, this.state.selectedCompany).then(({ data }) => {
                    let body;
                    let mergeData;
                    if (data.length !== 0) {
                        body = data[0];
                        body.clientId = data[0].id;
                        delete data[0].id
                        mergeData = {...this.state.profile, ...body}
                    } else {
                        mergeData = customerToSearch;
                    }
                    getTurn(queueId, branchId, mergeData);

                }).catch(err => {
                    console.error(err);
                    NotificationManager.error(i18next.t("AppContext.UNEXPECTED_ERROR"));
                    reject(err);
                });
            }
        });
    }

    getCompanyById(name) {
        return api.companyByName().getAll(name).then((response) => {
            if(this.state.profileList !== undefined && !(Object.keys(this.state.profileList).length === 0 && this.state.profileList.constructor === Object)) {
                let selectProfile = this.state.profileList[response.data.name];
                if (selectProfile !== undefined) {
                    this.updateState({ profile: selectProfile });
                } else {
                    this.updateState({ profile: {} });
                }
            }
            if (this.state.profileList === undefined) {
                this.createProfileList();
            }
            this.insertCheckIfCaptchaAvailable(response.data.captchaAvailable)
            if(response.data.css) {
                this.insertCustomCss(response.data.css);
            } else {
                this.updateState({ customCss: "" });
            }
            if(response.data && response.data.hasGoogleAnalytics && response.data.googleAnalyticsId) {
                Analytics.initialize(response.data.googleAnalyticsId);
            } else {
                Analytics.deinitialize();
            }
        });
    }



    getQueues() {
        return new Promise((resolve, reject) => {
            const { selectedCompany: name } = this.state;
            api.queues().getAll(name).then((response) => {
                let parsedQueuesArr = [];
                const parsedQueues = {};

                response.data.forEach((q) => {
                    if (parsedQueues.hasOwnProperty(q.name)) {
                        if (q.branch) {
                            q.branch.queueId = q.id;
                            if (q.defaultWaitingRoom) q.branch.defaultWaitingRoomIdForQueue = q.defaultWaitingRoom.id;
                            parsedQueues[q.name].branches.push(q.branch);
                        }
                    } else {
                        parsedQueues[q.name] = Object.assign({}, q);
                        delete parsedQueues[q.name].branch;
                        if (q.branch) {
                            q.branch.queueId = q.id;
                            if (q.defaultWaitingRoom) q.branch.defaultWaitingRoomIdForQueue = q.defaultWaitingRoom.id;
                            parsedQueues[q.name].branches = [q.branch];
                        }
                    }
                });

                for (const i in parsedQueues) {
                    if (parsedQueues.hasOwnProperty(i)) {
                        parsedQueuesArr.push(parsedQueues[i]);
                    }
                }
                resolve(parsedQueuesArr);
            }, (err) => {
                console.error("Request for queues failed", err);
                reject();
            });
        });
    }

    getAppointmentsQueues() {
        return new Promise((resolve, reject) => {
            let appointmentData = this.state.appointmentData;
            const companyName = this.state.selectedCompany;
            const scheduleId = appointmentData.appointment.schedule.id;
            let mBranchId = appointmentData.appointment.mBranchId;
            api.appoitmentsQueues().getAll(companyName, scheduleId, mBranchId).then((response) => {
                response.data.forEach((q) => {
                    q.branches = this.state.branches;
                });
                this.updateState({ queues: response.data }, this.checkTurnStatus);
                resolve(response.data);
            });
        })
    }

    getBranches() {
        return new Promise((resolve, reject) => {
            const { selectedQueue, selectedCompany: name } = this.state;
            let branches = [];

            if (selectedQueue.branches && selectedQueue.branches.length > 0) {
                branches = selectedQueue.branches;
            }

            api.branches().getAll(name).then((res) => {
                if (res && res.data && res.data.length) {
                    const mBranches = res.data;
                    if (branches.length === 0) {
                        branches = mBranches;
                    } else {
                        branches = branches.map(qBranch => {
                            const mBranch = mBranches.filter(b => b.id === qBranch.id)[0];
                            qBranch.mBranch = mBranch || {};

                            return qBranch;
                        });
                    }
                    resolve(branches);
                } else {
                    console.error("Branches data could not be loaded");
                    reject();
                }
            }, (err) => {
                console.error("Request for branches failed", err);
                reject();
            });
        });
    }

    resetActualTurn() {
        this.updateState({ turnCode: '', actualTurn: {}, selectedQueue: {}, isAppointment:false, appointmentCode: '', appointmentClientData: {}, appointmentData: {}, schedule: {}, checkInConfirmed: false, queues: [], selectedBranch: {}, preSelectedBranch: {} });
    }

    cancelTurn(force) {
        this.setTurnStatus("FINALIZED");
        return api.turn()
            .cancel(this.state.turnCode)
            .then(res => {
                this.resetActualTurn();
                if (typeof force === "function") force();
            }, res => {
                if (force && typeof force !== "function") {
                    this.updateState({ turnCode: '', actualTurn: {}, appointmentCode: '', appointmentTurn: '' });
                } else {
                    if(res.response.data.Error === undefined){
                        NotificationManager.error(i18next.t("AppContext.COULD_NOT_CANCEL", {res}));
                    }else{
                        NotificationManager.error(i18next.t("AppContext.COULD_NOT_CANCEL2", {res}));
                    }
                }
            });
    }

    /**
     * When the user click the login button
     */
    login(loginName, password) {
        api.auth()
            .login({ loginName, password })
            .then(res => {
                this.updateState({ isAuth: true }, () => this.props.history.push('/empresa'));
            })
            .catch(err => {
                NotificationManager.error(i18next.t("AppContext.ERROR_LOGIN"));
                this.updateState({ isAuth: false });
            });
    }

    /**
     * When the user click the signup button
     */
    signup(loginName, email, reEmail, password) {
        if (email !== reEmail) {
            NotificationManager.error(i18next.t("AppContext.EMAILS_NOT_MATCH"));
            return;
        }
        const ready = loginName && email && reEmail && password && password.length >= 4;
        if (!ready) {
            NotificationManager.error(i18next.t("AppContext.CHECK_FIELDS"));
            return;
        }

        const newCustomer = {
            email,
            password,
            loginName,
        };
        api.test()
            .createCustomer(newCustomer)
            .then(
                response => {
                    const loginData = {
                        loginName,
                        password,
                    };
                    api.auth()
                        .login(loginData)
                        .then(res => {
                            this.updateState({ isAuth: true }, () => this.props.history.push('/empresa'));
                        })
                        .catch(err => {
                            NotificationManager.error(i18next.t("AppContext.ERROR_LOGIN"));
                            this.updateState({ isAuth: false });
                        });
                },
                response => {
                    if (response.status === 409) {
                        NotificationManager.error(i18next.t("AppContext.ALREADY_USER_EMAIL"));
                    } else {
                        NotificationManager.error(i18next.t("AppContext.SERVER_ERROR"));
                    }
                }
            );
    }

    /**
     * Change the selected company state
     * @param selected
     * @param event
     */
    selectCompany(selected, event) {
        if(!selected)
            Analytics.deinitialize();

        if (event !== null && event !== undefined) {
            event.preventDefault();
            this.updateState({ selectedCompany: selected }, () => {
                this.props.history.push(`/buscar/${selected}`)
            })
        } else if (selected === null) {
            this.updateState({ selectedCompany: '' })
        } else {
            this.updateState({ selectedCompany: selected })
        }

    }

    /**
     * Insert custom Styles
     */

    insertCustomCss(styles) {
        this.updateState({ customCss: styles })
    }

    cleanPreSelect() {
        this.updateState({ preSelectedBranch: {} });
    }

    setTurnStatus(status) {
        switch (status) {
            case "FINALIZED":
                this.updateState({ actualTurnStatus: "FINALIZED" })
              break;
            case "VIDEOCALL_END":
                this.updateState({ actualTurnStatus: "VIDEOCALL_END" })
                break;
            case "LOST_CONNECTION":
                this.updateState({ actualTurnStatus: "LOST_CONNECTION" })
                break;
            case "EMPTY":
                this.updateState({ actualTurnStatus: {} })
              break;
          }
    }

    /**
     * Insert
     */
    insertCheckIfCaptchaAvailable(captcha) {
        this.updateState({ captchaIsAvailable: captcha })
    }


    /**
     * Goes back to the SearchCode View for the selected company
     */
    goToSearchCodeView() {
        this.props.history.push(`/buscar/${this.state.selectedCompany}`)
    }

    /**
     * Goes to confirm Turn
     */
    goToConfirm(queue) {
        if (queue.clientId !== undefined) {
            const branch = {
                id: queue.branch['cloudBranch.id'],
                name: queue.branch.name,
                label: queue.branch.label
            };
            let branches = [branch];
            this.updateState({ selectedQueue: queue, appointmentCode: queue.appointmentCode, selectedBranch: branch, mobileBranch: queue.branch['cloudBranch.id'], branches: branches }, this.checkTurnStatus);
            this.props.history.push('/confirmar');
        } else {
            this.updateState({ selectedQueue: queue }, () => {
                if(this.state.isAppointment) {
                    this.props.history.push('/confirmar');
                } else {
                    if(Object.keys(this.state.profile).length === 0) {
                        this.props.history.push('/profile');
                    } else {
                        this.props.history.push('/confirmar');
                    }
                }

            });
        }
    }

    /**
     * Change the selected queue state
     * @param queue
     */
    selectQueue(queue) {
        this.updateState({ selectedQueue: queue }, () => {
            this.props.history.push('/sucursales');
        });
    }

    /**
     * Save the list of queues in the state
     * @param queues
     * @param callback
     * @param ev
     */
    saveQueueList(queues, callback, ev) {
        if (ev instanceof Event) ev.preventDefault();
        this.updateState({ queues: queues }, callback);
    }

    /**
     * Save the list of branches in the state
     * @param branches
     * @param callback
     * @param ev
     */
    saveBranchList(branches, callback, ev) {
        if (ev instanceof Event) ev.preventDefault();
        this.updateState({ branches: branches }, callback);
    }

    /**
     * Change the selected branch state
     * @param branch
     * @param event
     */
    selectBranch(branch, callback) {
        this.updateState({ selectedBranch: branch }, () => {
            if (typeof callback === "function") {
                callback();
            }
        });
    }

    preSelectBranch(branch, callback) {
        this.updateState({ preSelectedBranch: branch }, () => {
            if (typeof callback === "function") {
                callback();
            }
        });
    }


    // Create profile list
    profilesList(data) {
        const companyName = this.state.selectedCompany;
        let list = this.state.profileList || {};
        list[companyName] = data;
        this.updateState({ profileList: list });
    }


    // Create profileList for old users

    createProfileList(){
        this.updateState({ profileList: {} });
    }

    // Check profile by companyName
    checkProfileByCompany() {
        const profileList = this.state.profileList || {};
        let selectProfile = profileList[this.state.selectedCompany];
        this.updateState({
            profile: selectProfile || {},
            profileList: profileList
        });
    }


    async updateProfile(form) {
        return new Promise((resolve) => {
            this.updateState({ profile: form }, resolve);
        });
    }

    render() {
        return (

            <Translation>
                {
                    (t) =>
                    <AppContext.Provider
                        value={{
                            t: t,
                            state: this.state,
                            actions: {
                                setAuth: this.setAuth,
                                login: this.login,
                                signup: this.signup,
                                updateProfile: this.updateProfile,
                                selectCompany: this.selectCompany,
                                insertCustomCss: this.insertCustomCss,
                                insertCheckIfCaptchaAvailable: this.insertCheckIfCaptchaAvailable,
                                goToSearchCodeView: this.goToSearchCodeView,
                                goToConfirm: this.goToConfirm,
                                selectQueue: this.selectQueue,
                                saveAppointmentClientData: this.saveAppointmentClientData,
                                saveQueueList: this.saveQueueList,
                                cleanQueues: this.cleanQueues,
                                preSelectBranchOrQueue: this.preSelectBranchOrQueue,
                                saveBranchList: this.saveBranchList,
                                selectBranch: this.selectBranch,
                                profilesList: this.profilesList,
                                checkProfileByCompany: this.checkProfileByCompany,
                                createProfileList: this.createProfileList,
                                preSelectBranch: this.preSelectBranch,
                                setTurnStatus : this.setTurnStatus,
                                setActualTurn: this.setActualTurn,
                                cleanPreSelect: this.cleanPreSelect,
                                setAppointmentTurn: this.setAppointmentTurn,
                                getCompanyById: this.getCompanyById,
                                cancelTurn: this.cancelTurn,
                                getTurn: this.getTurn,
                                getBranches: this.getBranches,
                                getQueues: this.getQueues,
                                getAppointmentsQueues: this.getAppointmentsQueues,
                                resetActualTurn: this.resetActualTurn,
                                checkIsAppointments: this.checkIsAppointments,
                                setCodeCheckIn: this.setCodeCheckIn,
                                checkInConfirmed: this.checkInConfirmed,

                            },
                        }}>
                    {this.props.children}

                    </AppContext.Provider>
                }
            </Translation>
        );
    }
}

export const AppProvider = withRouter(Context);
