import i18n from "i18next";
import { initReactI18next } from "react-i18next";

let lng = "es", fallbackLng= 'es';
let cbTranslationsLoaded;

const getLang = lang => {
    return fetch(process.env.PUBLIC_URL + "/static/lang/" + lang + ".json")
        .then(res => {
            if(!res.ok)
                throw new Error(res.status);
            return res.json();
        }).then(json => {
            i18n.addResourceBundle(lang, 'translation', json);
            if(cbTranslationsLoaded) cbTranslationsLoaded();
        }).catch(e=>{
            console.error("Error buscando idioma '" + lang + "'.", e);
        });
}

getLang(lng).finally(_=>{
    if(fallbackLng !== lng) getLang(fallbackLng)
});

i18n.use(initReactI18next)
    .init({
        resources:{},
        lng,
        fallbackLng,

        keySeparator: '.',
        interpolation: {
            escapeValue: false
        }
});

const i18nEvents = {
    onTranslationsLoaded: cb => {
        cbTranslationsLoaded = cb;
    }
};

export default i18n;
export {i18nEvents};