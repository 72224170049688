import React, { Component } from 'react';
import api from '../requests/api.js';
import { AppConsumer } from "../components/AppContext";
import Loader from '../components/Loader';
import { NotificationManager } from "react-notifications";
import TopBar from "../components/TopBar";
import ReCAPTCHA from "react-google-recaptcha";
import withTracker from '../utils/withTracker'
import * as Analytics from '../utils/Analytics'
import TrackedButton from "../components/TrackedButton";
import { encryptCustomer } from '../utils/Encryption.js';

class SearchView extends Component {
    constructor(props) {
        super(props)
        this.searchTurn = this.searchTurn.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelector = this.handleChangeSelector.bind(this);
        this.resetCaptcha = this.resetCaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.captchaToken = "";
        this.urlCode = "";
        this.urlParams = new URLSearchParams(window.location.search);
        this.state = {
            loading: false,
            searchCode: '',
            selectedBranch: {},
            selectedType: '',
            uuids: [],
            searchBtnTextValue: ""
        }
    }
    /**
     * Handle searchCode changes.
     * @param value
     */
    handleChange(value) {
        this.setState({
            searchCode: value.target.value
        });
    }
    handleChangeSelector(event) {
        this.setState({ selectedType: event.target.value });
    }



    /**
     * Gets all the available companies
     * @returns {Promise<void>}
     */
    async getUuids() {
        try {
            let companyName;
            if(this.context.state.selectedCompany === "" || this.context.state.selectedCompany === undefined){
                companyName = this.props.match.params.companyName
            } else {
                companyName = this.context.state.selectedCompany;
            }
            return await api.customerFieldsInfo().getAll(companyName);
        } catch (err) {
            console.log(err);
        }
    }

    async searchTurn() {
        const { actions, t } = this.context;

        if(this.context.state.captchaIsAvailable && !this.captchaToken){
            NotificationManager.error(t("SearchView.CONFIRM_TO_CONTINUE"));
            return;
        }

       
        const { searchCode, selectedType } = this.state;
        const urlParams = new URLSearchParams(window.location.search);
        let searchCodeUrl = urlParams.get('searchCode');
        let sCodeUrl = urlParams.get('s');
        const searchKey = searchCodeUrl ? searchCodeUrl : sCodeUrl;

        if(selectedType === "appointmentCode"){
             api.turn().searchTurnOrAppointmentWhitCaptcha(this.context.state.selectedCompany, searchCode, this.captchaToken).then(({ data }) => {
                 if (!data.hasOwnProperty('finishTime') || data.finishTime === "") {
                    if (data.isAppointment) {
                        actions.setCodeCheckIn();
                        // Tolerancia de cita
                        const checkInTolerance = JSON.parse(data.appointment.schedule.checkInTolerance);
                        const checkInToleranceBefore = checkInTolerance.before * 60000;
                        const checkInToleranceAfter = checkInTolerance.after * 60000;
                        // Horario de cita + su tolerancia
                        const startAt = (new Date(data.appointment.startAt)).getTime() - checkInToleranceBefore;
                        const endAt = (new Date(data.appointment.endAt)).getTime() + checkInToleranceAfter;
                       // Horario actual
                       const currentdate = Date.now();

                       // Chequeo que la cita esta en horario y no esta vencida
                        if (currentdate < startAt) {
                            NotificationManager.error(t("SearchView.OUT_OF_TIME"));
                            Analytics.event("search_failed");
                            this.resetCaptcha();
                        } else if (currentdate > endAt) {
                            NotificationManager.error(t("SearchView.APPOINTMENT_EXPIRED"));
                            Analytics.event("search_failed");
                            this.resetCaptcha();
                            if(!searchKey) {
                                this.props.history.push('/buscar/' + this.context.state.selectedCompany);
                            }
                        } else {
                            NotificationManager.success(t("SearchView.CORRECT_CODE"));
                            actions.setAppointmentTurn(data);
                            this.props.history.push('/tramites');
                            actions.checkInConfirmed(false);
                        }
                    } else {
                        if(data.turn.status === "CANCELED_BY_USER" || data.turn.status === "FINALIZED" || data.turn.status === "ABSENT"){
                            NotificationManager.error(t("SearchView.TURN_EXPIRED"));
                            this.resetCaptcha();
                        } else {
                            NotificationManager.success(t("SearchView.ACTIVE_TURN"));
                            actions.setActualTurn(data);
                            this.props.history.push('/turno');
                        }
                    }
                } else {
                    NotificationManager.error(t("SearchView.TURN_EXPIRED"));
                    Analytics.event("search_failed");
                    this.resetCaptcha();
                }
             }).catch(err => {
                 console.error(err);
                 NotificationManager.error(t("SearchView.INCORRECT_CODE_OR_APPOINTMENT"));
                 Analytics.event("search_failed");
                 this.setState({loading: false});
                 this.resetCaptcha();
             });
        } else {
            const body = {
                [selectedType]: this.state.searchCode
            }
            const pubkey = (await api.customers().getPublicKey()).data;
            const encryptedBody = encryptCustomer(body, pubkey);
            api.getCustomersWithUuidAndCaptcha().getAll(this.context.state.selectedCompany, this.captchaToken, encryptedBody).then(({ data }) => {
                const customers = data;
                if(customers.length > 0) {
                    let allAppointments = customers.reduce(function (appointments, client) {
                        if(!client.appointment) {
                            client.appointment = {};
                        }
                        client.appointment.id = client.id;
                        client.appointment.firstName = client.firstName;
                        client.appointment.lastName = client.lastName;
                        client.appointment.dni = client.dni;
                        client.appointment.cuil = client.cuil;
                        client.appointment.cuit = client.cuit;
                        if(client.appointment){
                            client.appointment.appointments = client.appointments;
                        }
                        if(client.appointment.extraFields){
                            client.extraFields = client.appointment.extraFields;
                        }
                        if(client.turns.length > 0) {
                            client.appointment.turns = client.turns;
                        }
                        appointments.push(client.appointment);
                        return appointments;
                    }, []);
                    if(allAppointments.length > 0){
                        actions.saveAppointmentClientData(allAppointments);
                        // Enviar a la siguiente pantalla de botones
                        this.props.history.push('/tramites');
                        actions.checkInConfirmed(false);
                    } else {
                        NotificationManager.error(t("SearchView.NO_APPOINTMENTS_SCHEDULED"));
                        this.resetCaptcha();
                    }
                } else {
                    NotificationManager.error(t("SearchView.DATA_NOT_FOUND"));
                    Analytics.event("search_failed");
                    this.resetCaptcha();
                }
            }).catch(err => {
                console.error(err);
                NotificationManager.error(t("SearchView.INCORRECT_CODE"));
                this.props.history.push('/buscarturno');
                this.resetCaptcha();
            });
        }

    }



    async componentDidMount() {
        this.resetCaptcha();
        const { match } = this.props;
        const { actions } = this.context;
        actions.setTurnStatus("EMPTY");
        let searchBtnTextValue = "SearchView.SEARCH";
        let preselectedCode = false;
        if (match.params && match.params.companyName) {
            const {params: { companyName }} = match;
            const urlParams = new URLSearchParams(window.location.search);
            let searchCode = false;
            let searchCodeUrl = urlParams.has('searchCode');
            let sCodeUrl = urlParams.has('s');
            if (searchCodeUrl || sCodeUrl) {
                searchCode = searchCodeUrl ? urlParams.get('searchCode') : urlParams.get('s');
                searchCode = atob(searchCode);
                preselectedCode = true;
                searchBtnTextValue = "SearchView.CONTINUE";
            }

            this.setState({loading: true});
            // This is so that the custom css and the captchaIsAvailable and Analytics are loaded
            actions.getCompanyById(companyName).then(_ => {
                if (searchCode) {
                    const {state: {captchaIsAvailable}} = this.context;
                    if (this.captchaToken || !captchaIsAvailable) {
                        this.setState({searchCode: searchCode, selectedType: 'appointmentCode'});
                        this.searchTurn();
                    } else {
                        this.setState({searchCode: searchCode, selectedType: 'appointmentCode', loading: false});
                    }
                } else {
                    this.setState({ loading: false});
                }
            }).catch( err => {
                console.log(err);
                this.props.history.push('/error');
            });

        } else if (this.context.state && this.context.state.selectedCompany){
            // This is so that the custom css and the captchaIsAvailable and Analytics are loaded
            actions.getCompanyById(this.context.state.selectedCompany);
        }
        this.setState({searchBtnTextValue, preselectedCode})

        const req = await this.getUuids();
        const uuids = req ? req.data : [];
        uuids.forEach((label) => {
            const toCamel = (s) => {
                return s.replace(/([-_][a-z])/ig, ($1) => {
                  return $1.toUpperCase()
                    .replace('-', '')
                    .replace('_', '');
                });
              };

              label.name = toCamel(label.name);
        });
        //Filtrar uuids sin prioridad
        const filterUUids = uuids.filter(uuid => {
            return uuid.priority !== null
        });
        // Ordeno UUids
        filterUUids.sort(function(a, b) {
            return a.priority - b.priority;
        });
        this.setState({ uuids: filterUUids, loading: false });
    }
    verifyCallback(recaptchaToken) {
        this.urlCode = this.urlParams.has('searchCode') || this.urlParams.has('s');
        this.captchaToken = recaptchaToken;
        if(this.urlCode && this.captchaToken) {
            Analytics.event("search_code");
            this.searchTurn();
        }
    }

    resetCaptcha(){
        if (this.searchCaptcha) {
            this.searchCaptcha.reset();
        }
    }

    render() {
        const { loading, searchCode, selectedType, uuids, preselectedCode, searchBtnTextValue} = this.state;
        const hideBarAndButtons = ".menu-bar {display: none;} .deb-back-button {display: none;}"
        return (
            <Loader loading={loading} fullscreen={false}>
            {preselectedCode &&
                <style>
                    {/* Hide navs button */}
                    {hideBarAndButtons}
                </style>
            }
                <div className="viewSearchCode">
                    <TopBar
                        linkBack={"/buscar/" + this.context.state.selectedCompany}
                        title={this.context.state.selectedCompany}
                        id={"btnBackSearchView"}
                    />
                    <AppConsumer>
                        {({t}) => (
                            <div className="content">
                                <div className="container-body">
                                    <div className="card card-info">
                                        <div className="col search-container">
                                            {   preselectedCode ?
                                                    <div>
                                                        <p className="deb-text" id="searchViewMainText">
                                                            { t("SearchView.CONFIRM_CAPTCHA") }
                                                        </p>
                                                    </div>
                                                :
                                                    <div>
                                                        <p className="deb-text" id="searchViewSecondaryText">
                                                            { t("SearchView.INSERT_CODE") }
                                                        </p>

                                                        <p className="deb-text" dangerouslySetInnerHTML={{__html: t("SearchView.REMINDER_CHECKIN_APPO")}}/>

                                                        <div className="list list-inset">
                                                            <div className="box-select">
                                                                <select
                                                                    name="company"
                                                                    className="app-select"
                                                                    value={selectedType}
                                                                    onChange={this.handleChangeSelector}>
                                                                    {selectedType === '' && (
                                                                        <option value="-" selected>{ t("SearchView.SELECT_SEARCH_TYPE") }</option>
                                                                    )}
                                                                    {uuids.map((uuid, i) => (
                                                                        uuid.uuid &&
                                                                        <option key={i} value={uuid.name}>
                                                                            {uuid.label}
                                                                        </option>
                                                                    ))}
                                                                    <option value="appointmentCode">{ t("SearchView.OPTION_CODE") }</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                            <div className="list list-inset search-input">
                                                <label className={"item item-input " + (preselectedCode ? " disabled-input " : "")} id="labelInputSearch">
                                                    <i className="icon ion-search placeholder-icon"></i>
                                                    <input
                                                        type="text"
                                                        placeholder={ t("SearchView.SEARCH_INPUT") }
                                                        id="searchInput"
                                                        disabled={preselectedCode}
                                                        onChange={this.handleChange}
                                                        value={searchCode} />
                                                </label>
                                            </div>
                                            {this.context.state.captchaIsAvailable &&
                                            <div className="list list-inset captcha-container">
                                                <ReCAPTCHA
                                                    ref={(el) => { this.searchCaptcha = el; }}
                                                    sitekey="6Ldt0gAVAAAAAGVHDBF3kvUy2I2IkltzgjNENxdY"
                                                    hl={"es"}
                                                    onChange={this.verifyCallback}
                                                    size="compact"
                                                />
                                            </div>
                                            }
                                            <TrackedButton id="search_code" className="button button-calm button-block button-primary" disabled={!searchCode} onClick={this.searchTurn}>
                                                {t(searchBtnTextValue)}
                                            </TrackedButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </AppConsumer>
                </div>
            </Loader>
        )
    }
}
export default withTracker(SearchView);
SearchView.contextType = AppConsumer;
