import React, { Component } from 'react';
import { AppConsumer } from '../components/AppContext';
import withTracker from "../utils/withTracker";


class Videocall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videocallEnded: false
        };
        this.iframeMessageListener = this.iframeMessageListener.bind(this);
        this.focusVideocall = false;
    }

    goHome(){
        this.context.actions.goToSearchCodeView();
    }

    sendReattachMessage = (data)=>{
        const initDataForVideocall = {};
        initDataForVideocall.event = "reattach";
        if (data) {
            initDataForVideocall.yourusername = data.yourusername
            initDataForVideocall.myusername = data.myusername
            initDataForVideocall.turnId = data.turnId
        }

        if (this.iframe) {
            document.getElementsByTagName("iframe")[0].contentWindow.postMessage(initDataForVideocall, "*");
        }
    };

    sendDestroyMessage = () => {
        const deinitDataForVideocall = {
            event: "destroy"
        };

        if (this.iframe) {
            document.getElementsByTagName("iframe")[0].contentWindow.postMessage(deinitDataForVideocall, "*");
        }
    };

    sendFocusRegained = () => {
        const focusRegainedData = {
            event: "refocused"
        };


        if (this.iframe) {
            document.getElementsByTagName("iframe")[0].contentWindow.postMessage(focusRegainedData, "*");
        }

        window.removeEventListener('focus', this.sendFocusRegained);
    };



    iframeMessageListener(ev){
        if (ev.data === "focusVideoCall") {
            this.focusVideocall = true;
        }
        console.debug("Got message from videocall iframe", ev.data);
        if(ev.data === "incomingCall" || ev.data === "callStarted"){
            this.context.actions.setTurnStatus("EMPTY");
            if (this.props.onVideocallStarted) this.props.onVideocallStarted();
        } else if (ev.data === "closeModalVideoCall" || ev.data === "videocallFinished"){
                if(this.focusVideocall) {
                    console.log("Videocall Focused");
                    this.focusVideocall = false;
                } else {
                    this.context.actions.setTurnStatus("VIDEOCALL_END");
                    if (this.props.onVideocallEnded) this.props.onVideocallEnded();
                    this.setState({
                        videocallEnded: true
                    });
                }
        } else if (ev.data.event) {
            const {data} = ev;
            if (data.event === "cleanup") {
                console.log("Got cleanup message from videocall. Will reconnect in case it is needed");
                this.reattachTimeout = setTimeout(this.sendReattachMessage, 800, data);
                this.destroyTimeout = setTimeout(this.sendDestroyMessage, 100, data);
            } else if (data.event === "destroy") {
                clearTimeout(this.destroyTimeout);
            } else if (data.event === "answerFailed") {
                window.addEventListener('focus', this.sendFocusRegained);
            }
        }
    }

    componentDidMount() {
        window.addEventListener('message', this.iframeMessageListener);
    }

    componentWillUnmount() {
        window.removeEventListener('message',  this.iframeMessageListener);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.attentionFinished !== this.props.attentionFinished) {
            if (this.props.attentionFinished) {
                console.log("The turn has finished. Will destroy janus.");
                clearTimeout(this.reattachTimeout);
                this.sendDestroyMessage();
            }
        }
    }
    

    render() {
        const isIphone = navigator.userAgent.indexOf("iPhone") !== -1;
        this.iframe = <iframe id={"videocallIframe"} src={this.context.state.actualTurn.videoCallUrl+'?videocallUser=mobile&companyName='+this.context.state.selectedCompany} className="videocallIframe"
                              frameBorder="0"
                              allow={isIphone ? "geolocation; microphone; camera; midi; encrypted-media" : "geolocation *; microphone *; camera *; midi *; encrypted-media *"}/>;
        return (
            <div className="videocallView">
                <AppConsumer>
                    {({state, actions}) => (
                        <div>

                        {
                            this.context.state.actualTurn.status !== "WAITING_TO_BE_CALLED" && this.context.state.actualTurnStatus !== "LOST_CONNECTION" &&
                            <div className="videocallContainer">
                            {this.iframe}
                            </div>
                        }
                        </div>
                        )}
                </AppConsumer>
            </div>
        );
    }
}

Videocall.contextType = AppConsumer;
export default withTracker(Videocall);

