import React, { Component } from 'react';
import { AppConsumer } from '../components/AppContext';
import img from '../img/login_img.png';
import TopBar from "../components/TopBar";
import api from '../requests/api.js';


class Profile extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.savedProfile = this.savedProfile.bind(this);
        this.state = {
            firstName: '',
            lastName: '',
            dni: '',
            phone: '',
            email: '',
            cuit: '',
            cuil: '',
            libretaCivica: '',
            libretaEnrolamiento: '',
            passport: '',
            cedulaIdentidad: '',
            cedulaDiplomatica: '',
            cardNumber: '',
            customerNumber: '',
            profileForm: [],
        };
        this.fieldsNumberType=[];
    }


    async getInfoCustomer() {
        try {
            const companyName = this.context.state.selectedCompany;
            return await api.customerFieldsInfo().getAll(companyName);
        } catch (err) {
            console.log(err);
        }
    }

    async componentDidMount() {
        const { profile } = this.context.state;
        this.setState({ ...profile });

        const req = await this.getInfoCustomer();
        const customerFields = req ? req.data : [];

        const toCamel = (s) => {
            return s.replace(/([-_][a-z])/ig, ($1) => {
                return $1.toUpperCase()
                    .replace('-', '')
                    .replace('_', '');
            });
        };
        customerFields.forEach((field) => {
            var fieldName = toCamel(field.name);
            if (field.type==="number"){
                 field.type="text";
                 this.fieldsNumberType.push(fieldName);
            }
            if (field.name === "phone_type" || field.name === "phoneType") {
                field.show = false
            }
            field.name = fieldName;
        });
        this.setState({ profileForm: customerFields });
    }

    /**
     * Update the inputs
     * @param event
     */
    handleChange(event) {
        let field = event.target;
        let numericType = this.fieldsNumberType.includes(field.name);
        let inputValid = ((this.isNumber(field.value) && numericType) || (!numericType||field.value.length===0));
        if (inputValid) this.setState({ [field.name]: field.value });
    }


    savedProfile(ev) {
        ev.preventDefault();

        const { actions } = this.context;
        const { firstName, lastName, dni, phone, email, cuit, cuil, libretaCivica, libretaEnrolamiento, passport, cedulaIdentidad, cedulaDiplomatica, cardNumber, customerNumber } = this.state;

        // Creo el objeto par que se vea mas bonito aca
        let profile = { firstName, lastName, dni, phone, email, cuit, cuil, libretaCivica, libretaEnrolamiento, passport, cedulaIdentidad, cedulaDiplomatica, cardNumber, customerNumber };
        if (!this.hasAnyData()) {
            this.goBack();
        } else {
            actions.profilesList(profile);
            if (this.context.state.preSelectedBranch.id !== undefined) {
                actions.updateProfile.call(this, profile).then(_=> this.props.history.push('/confirmar'))
            } else {
                const actionAfterSave = this.props.saveHandler || this.goBack;
                actions.updateProfile.call(this, profile).then(actionAfterSave);
            }
        }
    }

    goBack = () => {
        if (typeof this.props.formClose === "function") {
            this.props.formClose();
            return;
        }
        const { history } = this.props;
        history.goBack();
    };

    goToConfirm = ()=>{
        this.props.history.push('/confirmar');
    };

    hasAnyData(){
        return this.state.profileForm.some(
            field => this.state[field.name]
        );
    }

    isNumber(field){
        let regex = /^[0-9]+$/;
        return regex.test(field);
    }

    render() {
        const { profileForm } = this.state;

        return (
            <AppConsumer>
                    {({ actions, t }) => (
                   
                   <div className="viewProfile">
                        <TopBar
                            title={t("Profile.TITLE")}
                            linkHandler={this.goBack}
                                />
                        
                                <div className="container-body content">
                                    <div className="card card-info">
                                        <form
                                            onSubmit={this.savedProfile}
                                            className="content">
                                            <div className="viewProfile__imgContainer ">
                                                <img src={img} alt={t("Profile.PROFILE_IMAGE")}
                                                    className="img-responsive viewProfile__img viewSelectQueue__maxImg" />
                                            </div>
                                            <div
                                                className={'viewProfile__form-container'}>
                                                <p className="viewProfile__text deb-text" dangerouslySetInnerHTML={{__html: t("Profile.PROFILE_TEXT")}}/>
                                                {/*TODO: Me parece que el margin de esta lista está de más, asegurarse*/}
                                                <div className="viewProfile__form">
                                                    {profileForm.map((field, i) => (
                                                        field.show &&
                                                        <label className={"viewProfile__input"} key={i} id={field.name+"_label"}>
                                                            <input
                                                                type={field.type}
                                                                name={ field.name }
                                                                maxLength={Number(field.maxLength)}
                                                                placeholder={t(field.label ) + (field.required ? '*' : '')}
                                                                value={this.state[field.name]}
                                                                required={field.required}
                                                                onChange={this.handleChange}
                                                            />
                                                        </label>
                                                    ))}
                                                </div>
                                                <button type={"submit"}
                                                    disabled={!this.hasAnyData()}
                                                    className="button button-calm button-block button-primary">
                                                    {t("Profile.PROFILE_SAVE")}
                                                </button>
                                                {(typeof this.props.skipHandler === "function" &&
                                                    (
                                                        <button type={"button"} onClick={this.props.skipHandler}
                                                            className="button button-light viewProfile__secondary-button item">
                                                            {t("Profile.PROFILE_NEXT")}
                                                        </button>
                                                    )
                                                )}
                                                {/* Skip Button only for preselected branch */}
                                                {(this.context.state.preSelectedBranch.id !== undefined &&
                                                    (
                                                        <button type={"button"} onClick={this.goToConfirm}
                                                            className="button button-light viewProfile__secondary-button item">
                                                            {t("Profile.PROFILE_NEXT")}
                                                        </button>
                                                    )
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                        
                    </div>
             )}
            </AppConsumer>
        );
    }
}
Profile.contextType = AppConsumer;
export default Profile;
