import React, { Component } from 'react';
import api from '../requests/api.js';
import { Link } from 'react-router-dom';
import { AppConsumer } from "../components/AppContext";
import Loader from '../components/Loader';
import { NotificationManager } from "react-notifications";
import TopBar from "../components/TopBar";
import withTracker from "../utils/withTracker";

class SearchCode extends Component {
    constructor(props) {
        super(props);
        this.goToQueues = this.goToQueues.bind(this);
        this.goToTurn = this.goToTurn.bind(this);
        this.searchTurn = this.searchTurn.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.checkTurnStatus = this.checkTurnStatus.bind(this);
        this.state = {
            loading: false,
            searchCode: '',
            showNew : true,
            queues: [],
            branches: [],
            preSelectedBranch: {},
        }
    }

    /**
     * Handle searchCode changes.
     * @param value
     */
    handleChange(value) {
        this.setState({
            searchCode: value.target.value
        });
    }



    /**
     * Go to the queue list
     */
    goToQueues() {
        const { actions } = this.context;
        actions.checkIsAppointments();
        this.props.history.push('/tramites');
    }

    /**
     * Go to the turn
     */
    goToTurn() {
        const { state, t } = this.context;
       
        if (state.checkInConfirmed === false && state.isAppointment === true) {
            NotificationManager.warning(t("SearchCode.CHECKIN_INFORMATION"));
            if (Object.keys(state.selectedQueue).length === 0 && state.selectedQueue.constructor === Object) {
                this.props.history.push('/tramites');
            } else {
                this.props.history.push('/confirmar');
            }
        } else if (state.checkInConfirmed === true && state.isAppointment === true){
            this.props.history.push('/turno');
        } else if (state.isAppointment === false) {
            this.props.history.push('/turno');
        }
    }

    searchTurn() {
        const { actions, t } = this.context;
        const { searchCode } = this.state;
        api.turn().searchturn(searchCode).then(({data}) => {
            if (!data.hasOwnProperty('finishTime') || data.finishTime === "") {
                actions.setActualTurn(data);
                this.setState({showNew: false});
                NotificationManager.success(t("SearchCode.ACTIVE_TURN_DETAILS"));
            } else {
                NotificationManager.error(t("SearchCode.TURN_EXPIRED"));
            }
        }).catch( err => {
            console.log(err);
            NotificationManager.error(t("SearchCode.INCORRECT_CODE"));
        });
    }

    checkTurnStatus(code){
        const { t } = this.context;
        if(process.env.NODE_ENV === 'development'){
            // Obtengo un ejemplo de turno finalizado
            api.test().finishedturn().then(res => {
                if(res.data.hasOwnProperty('finishTime')){
                    this.context.actions.setActualTurn(res.data);
                    this.setState({showNew: true});
                    clearInterval(this.interval);
                    console.log("Se obtuvo turno caducado para hacer pruebas");
                }
            })
        } else {
            api.turn().searchturn(code).then(res => {
                if(res.data.hasOwnProperty('finishTime') && this.context.state.actualTurn.finishTime !== ""){
                    this.context.actions.setActualTurn(res.data);
                    this.setState({showNew: true});
                    clearInterval(this.interval);
                    console.info("Turno caducado");
                }
            })
        }
    }

    /**
     * Gets all the available companies
     * @returns {Promise<void>}
     */
    async getCompanies() {
        try {
            return await api.companies().getAll();
        } catch (err) {
            return Promise.resolve({ data: [] });
        }
    }

    async componentDidMount() {
        const ctx = this.context;
        const { history, match } = this.props;
        ctx.actions.getCompanyById(match.params.name)
        ctx.actions.setTurnStatus("EMPTY");
        ctx.actions.cleanPreSelect();
        if (match.params.name === undefined){
            history.push('/error');
        } else {
            const companies = await this.getCompanies();
            if (!companies.data.some(company => company.name === match.params.name)) {
                history.push('/error');
            }
            const urlParams = new URLSearchParams(window.location.search);
            const branchId = parseInt(urlParams.get('branchId'));
            const queueName = urlParams.get('queueName');
            const branchName = urlParams.get('branchName');
            this.context.actions.preSelectBranchOrQueue({
                branchId: branchId,
                queueName: queueName,
                branchName: branchName
            });
            const goToQueues = urlParams.get('goToQueues');
            if (branchId || queueName || branchName) {
                this.setState({ loading: true });
                this.context.actions.cleanQueues();
            }

            // Si la URL es distinta al estado, seteo el valor de la URL
            if(match.params.name !== ctx.state.selectedCompany){
                ctx.actions.selectCompany(match.params.name, null);
            }
            // Si existe un turnCode y el último estado guardado no tiene finishTime (o sea que estaba activo)
            if ((ctx.state.turnCode || ctx.state.appointmentCode) && (!ctx.state.actualTurn.hasOwnProperty('finishTime') || ctx.state.actualTurn.finishTime === "")) {
                // Habilito el boton para ver los detalles
                this.setState({ showNew: false });
                // Cada 30 segundos me fijo si sigue activo
                this.interval = setInterval(() => {
                    this.checkTurnStatus(ctx.state.turnCode);
                }, 30000);
            } else if (!(branchId || queueName || branchName)) {
                this.setState({ loading: false });
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { showNew, loading } = this.state;
        const { state } = this.context;

        return (
            <Loader loading={loading} fullscreen={false}>
            <div className="viewSearchCode">
                <TopBar
                    title={state.selectedCompany}
                />
                <AppConsumer>
                    {({t}) => (
                        <div className="content">
                            <div className="container-body container-body__searchCode">
                                <div className="card card-info">
                                    <div className="col newTurn-container">
                                        <h2 className="deb-main-title"> {t("SearchCode.TURNS")} </h2>
                                        {
                                            !showNew ? (
                                                    <p className="deb-text">
                                                        { t("SearchCode.TURN_STILL_ACTIVE") } <br />
                                                        <strong>{ t("SearchCode.TURN_CODE", {code: state.turnCode || state.appointmentCode}) } </strong>
                                                        <button className="button button-calm button-block button-primary" onClick={this.goToTurn}>Ver detalles</button>
                                                    </p> )
                                                : (
                                                    <p className="deb-text">
                                                        <span dangerouslySetInnerHTML={{__html: t("SearchCode.GET_TURN_LONG")}}/>
                                                        <button className="button button-calm button-block button-primary" onClick={this.goToQueues}>
                                                            {t("SearchCode.GET_TURN")}
                                                        </button>
                                                    </p>)
                                        }
                                    </div>
                                    <div className="col search-container checkIn-container">
                                        <p className="deb-text">
                                            <span dangerouslySetInnerHTML={{__html: t("SearchCode.FIND_TURN_LONG")}}/>
                                        </p>
                                        <Link to="/buscarturno">
                                            <button className="button button-calm button-block button-primary">
                                                { t("SearchCode.FIND_TURN") }
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </AppConsumer>
            </div>
            </Loader>
        );
    }
}

SearchCode.contextType = AppConsumer;
export default withTracker(SearchCode);
