import React, { Component } from 'react';
import { AppConsumer } from '../components/AppContext';
import TurnView from "./TurnView";
import Videocall from "./Videocall";
import {NotificationManager} from "react-notifications";
import withTracker from "../utils/withTracker";


class VideocallWithTurnView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showVideoCall: false,
        };
    }

    onVideoCallStarted(){
        this.setState({ showVideoCall: true });
    }

    onVideoCallEnded(){
        const { t } = this.context;
        this.setState({ showVideoCall: false });
        console.log("Videocall ended. Will notify user");
        NotificationManager.info(t("VideocallWithTurnView.VIDEOCALL_ENDED"));
    }

    render() {
        const { showVideoCall } = this.state;
        const {state: {actualTurn}} = this.context;

        const turnFinished = actualTurn && (actualTurn.hasOwnProperty('finishTime') && actualTurn.finishTime !== "");
        return(
            <div>
                {
                    !showVideoCall && <TurnView isWaitingForVideocall turn={actualTurn}/>
                }
                <div style={{"display": (showVideoCall ? "block" : "none")}}>
                    <Videocall onVideocallStarted={_ => this.onVideoCallStarted()}
                               onVideocallEnded={_ => this.onVideoCallEnded()} attentionFinished={turnFinished}/>
                </div>
            </div>
        );
    }
}

VideocallWithTurnView.contextType = AppConsumer;
export default withTracker(VideocallWithTurnView);
