import React, { Component } from 'react';
import api from '../requests/api';
import { AppConsumer } from '../components/AppContext';
import Loader from '../components/Loader';
import {anchorTime} from "../helpers";
import { NotificationManager } from "react-notifications";
import TopBar from "../components/TopBar";
import BranchesList from "./BranchesList";
import BranchesMap from "./BranchesMap";
import { Route , withRouter} from 'react-router-dom';
import i18next from 'i18next';


const daysOfTheWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

const daysTranslations = {
    Domingo: "Branches.SUNDAY",
    Lunes: "Branches.MONDAY",
    Martes: "Branches.TUESDAY",
    Miércoles: "Branches.WEDNESDAY",
    Jueves: "Branches.THURSDAY",
    Viernes: "Branches.FRIDAY",
    Sábado: "Branches.SATURDAY"
} 

class Branches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branches: [],
            selectedBranch: {},
            loading: true,
            showMap: true,
            listOnly: false,
            sorting: Branches.BranchSorting.DISTANCE
        };
    }

    /**
     * Get the list of the available branches
     * @returns {Promise}
     */
    getBranches() {
        return new Promise(((resolve, reject) => {
            const {actions: {getBranches}} = this.context;

            getBranches().then((branches) => {
                branches = branches.filter(br => !!(br.id));
                branches = branches.map(br => {
                    if (br.monitorInfo && br.monitorInfo.name && !br.name) {
                        br.name = br.monitorInfo.name;
                    }
                    return br;
                });

                const shouldHideMap = branches.every((br) => {
                    return !Branches.branchHasGeolocationData(br);
                });
                if (shouldHideMap) this.setState({showMap: false, listOnly: true});
                resolve(branches);
            }, resolve.bind(this, undefined));
        }));
    }

    static branchHasGeolocationData = function (branch) {
        return (typeof branch.latitude !== "undefined" && typeof branch.longitude !== "undefined") ||
            (branch.mBranch && typeof branch.mBranch.latitude !== "undefined" && typeof branch.mBranch.longitude !== "undefined");
    };

    //TODO: Tidy this up
    static getBranchGeolocationData = function (branch) {
        if (Branches.branchHasGeolocationData(branch)) {
            if (typeof branch.latitude !== "undefined") {
                return {lat: parseFloat(branch.latitude), lng: parseFloat(branch.longitude)};
            } else {
                return {lat: parseFloat(branch.mBranch.latitude), lng: parseFloat(branch.mBranch.longitude)};
            }
        }
    };

    /**
     * Set a queue in the selectedBranch state
     * @param {Object} selected
     */
    selectBranch = (selected) => {
        this.setState({selectedBranch: selected});
        return this.getBranchInfo(selected) || new Promise(res=>res());
    };

    getBranchInfo(branch){
        if(branch.monitorInfo) return;
        let branchInList = this.state.branches.find(b=>b.id===branch.id);
        if(branchInList.monitorInfo){
            branch.monitorInfo = branchInList.monitorInfo;
            return;
        }
        return api.queues().branchInfo(branch.id).then(res=>{
            branch.monitorInfo = res.data.monitorInfo;
            const {branches} = this.state;
            branches.forEach(b=>{
                if(b.id===branch.id) b.monitorInfo = res.data.monitorInfo;
            });
            this.setState({branches: branches});
        });
    }


    async componentDidMount() {
        const { state, t } = this.context;
        const { history } = this.props;
        if (state.actualTurn.code && !state.actualTurn.finishTime) {
            NotificationManager.warning(t("Branches.CANCEL_BEFORE"));
            if(state.actualTurn.videoCallUrl && state.actualTurn.videoCallUrl !== "") {
                history.push('/videocall');
            } else {
                history.push('/turno');
            }
        }
        if (this.context.state.profileList === undefined) {
            this.context.actions.createProfileList();
        }
        this.getBranches().then((req) => {
            if(req === undefined){
                NotificationManager.error(t("Branches.ERROR"));
                this.props.history.push('/buscar/' + state.selectedCompany);
            }else{
                const branches = req.map(br => {
                    br.open = Branches.isOpen(br);
                    return br;
                });

                let selected = this.state.selectedBranch;

                if (this.props.selected) {
                    selected = branches.filter((b) => {
                        return b.id === this.props.selected.id;
                    })[0];
                }

                Branches.getGeolocationIfAvailable(async (pos) => {
                    const branchesByDistance = await this.getBranchesSortedByDistance(pos, branches);

                    this.setState({selectedBranch: selected, sorting: Branches.BranchSorting.DISTANCE,
                        branches: branchesByDistance, loading: false});
                }, pos => {
                    this.setState({selectedBranch: selected, branches: branches, loading: false, showMap: false});
                } );
            }
        });
    }

    static renderBranchInfo(title="", value="") {
        return (
            <div className={"info-row"}>
                <div className="info-left">
                    {title}
                </div>
                <div className="info-right">
                    {value}
                </div>
            </div>
        );
    }

    static getBranchTitle(branch) {
        return (branch.label || (branch.mBranch && branch.mBranch.name) || branch.name);
    }

    static getBranchOpenDetails(branch, justToday) {
        let schedule;
        if (Branches.hasOpenDetails(branch)) {
            schedule = branch.openDetails;
        } else if (Branches.hasOpenDetails(branch.mBranch)) {
            schedule = branch.mBranch.openDetails;
        } else {
            return false;
        }

        const stringifyHours = (interval, pos, arr) => {
            let text = i18next.t("Branches.FROM_TO",  {interval});
            if (arr[pos + 1]) {
                if (!arr[pos + 2]) {
                    text += i18next.t("Branches.AND");
                } else {
                    text += ", ";
                }
            }
            return `${text}`;
        }
        const scheduleHours = {};
        for (const scheduleElement of schedule) {
            if (daysOfTheWeek.indexOf(scheduleElement.dayOfWeek) > -1) {
                if (scheduleElement.hours && scheduleElement.hours.length) {
                    scheduleHours[scheduleElement.dayOfWeek] = scheduleElement.hours.map(stringifyHours);
                } else {
                    scheduleHours[scheduleElement.dayOfWeek] = [(i18next.t("Branches.CLOSED"))];
                }
            }
        }

        schedule = [];
        for (let i = 0; i < daysOfTheWeek.length; i++) {
            const daySchedule = {day:daysOfTheWeek[i]};
            if (scheduleHours[daySchedule.day]) {
                daySchedule.hours = scheduleHours[daySchedule.day];
            } else {
                daySchedule.hours = [(i18next.t("Branches.CLOSED"))];
            }
            schedule[i] = daySchedule;
        }

        if (justToday) schedule = schedule.filter(dayOpenDetails => {
            const today = new Date().getDay();
            return daysOfTheWeek[today] === dayOpenDetails.day;
        });

        return schedule;
    }

    static renderBranchOpenDetails(branch, justToday, t) {
        let schedule = this.getBranchOpenDetails(branch, justToday);
        if (!schedule) {
            return false;
        }
        schedule = schedule.map(daySchedule=>{
            return (<>
                {t(daysTranslations[daySchedule.day])}  {daySchedule.hours.map(h => (<>{h}<br/></>))}
                </>);
        });
        return this.renderBranchInfo(t("Branches.SCHEDULE"), schedule);
    }

    static getIntervalHours(interval) {
        let resp = {};
        if (typeof interval.from === "string") {
            let intervalOpenHour = interval.from.match(/\d\d:/mig);
            let intervalOpenMinute = interval.from.match(/:\d\d/mig);
            if (intervalOpenHour.length > 0 || intervalOpenMinute.length > 0) {
                intervalOpenHour = intervalOpenHour[0].slice(0, -1);
                intervalOpenMinute = intervalOpenMinute[0].slice(1);
                resp.from = [intervalOpenHour, intervalOpenMinute];
            }
        }

        if (typeof interval.to === "string") {
            let intervalCloseHour = interval.to.match(/\d\d:/mig);
            let intervalCloseMinute = interval.to.match(/:\d\d/mig);
            if (intervalCloseHour.length > 0 || intervalCloseMinute.length > 0) {
                intervalCloseHour = intervalCloseHour[0].slice(0, -1);
                intervalCloseMinute = intervalCloseMinute[0].slice(1);
                resp.to = [intervalCloseHour, intervalCloseMinute];
            }
        }

        return resp;
    }

    toggleMap = (ev) => {
        this.setState({showMap: !this.state.showMap});
    };

    branchSelectedHandler = (ev) => {
        ev.preventDefault();
        const {actions} = this.context;
        actions.selectBranch(this.state.selectedBranch, ()=>{
            actions.saveBranchList(this.state.branches, () => {
                if (typeof this.props.branchSelectedHandler === "function") {
                    this.props.branchSelectedHandler();
                }
            });
        })
    };

    branchSelectedChange = (ev) => {
        ev.preventDefault();
    };

    static hasOpenDetails (branch) {
        return branch && branch.openDetails;
    }

    static isOpen(branch) {
        const time = new Date();
        if (!Branches.hasOpenDetails(branch)) {
            if (Branches.hasOpenDetails(branch.mBranch)) {
                branch = branch.mBranch;
            } else {
                return true;
            }
        }
        const dayOfTheWeek = time.getDay();

        let todayOpenDetails = branch.openDetails.filter((day) => day.dayOfWeek === daysOfTheWeek[dayOfTheWeek])[0];

        if (todayOpenDetails && todayOpenDetails.hours && todayOpenDetails.hours.length > 0) {
            for (let i = 0; i < todayOpenDetails.hours.length; i++) {

                const interval = todayOpenDetails.hours[i];
                const hours = Branches.getIntervalHours(interval);

                const openingTime = new Date(time.getFullYear(), time.getMonth(), time.getDate(), hours.from[0], hours.from[1]);
                const closingTime = new Date(time.getFullYear(), time.getMonth(), time.getDate(), hours.to[0], hours.to[1]);

                return !(openingTime > time || closingTime < time);
            }
        } else {
            return false;
        }
    }

    getBranchesSortedByDistance = async (pos, branches) => {
        const lat = pos.coords.latitude;
        const long = pos.coords.longitude;
        const {selectedCompany: companyName} = this.context.state;

        // TODO: If there's time to implement it, there should probably be some sort of loader to show that computing is underway

        let resp;
        try {
            resp = await api.branches().computeDistances(companyName, lat, long);
        } catch(e) {
            console.error("Failed to sort branches by distance", e);
            return branches;
        }
        // Response format {id: 12, distanceInMeters: 1200}
        let distances = {};

        resp.data.forEach((d) => {
            distances[d.id] = d.distanceInMeters;
        });

        branches = branches.sort((a, b) => {
            if (!distances[a.id] && distances[a.id] !== 0) {
                return 1;
            }
            if (!distances[b.id] && distances[b.id] !== 0) {
                return -1;
            }
            return distances[a.id] - distances[b.id];
        });

        return branches.map((b) => {
            b.distance = distances[b.id];

            return b;
        });
    };

    static getGeolocationIfAvailable = (callback, errorCallback) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(callback, (err) => {
                    let msg = "Cannot get geolocation. ";
                    if (!err.code) {
                        console.error(msg, err);
                    } else if (err.code === 1) {
                        msg += "User declined geolocation. ";
                    } else {
                        msg += "Error code: " + err.code;
                    }

                    console.error(msg);
                    if (typeof errorCallback === "function") errorCallback(msg);
                },
                {
                    maximumAge: 120 * 1000,
                    timeout: 10 * 1000
                });
        } else {
            console.error("Geolocation is not available, cannot sort branches by distance");
        }
    };

    static BranchSorting = {ALPHABETICAL:{}, DISTANCE: {}};

    toggleBranchSorting = () => {
        if (this.state.sorting === Branches.BranchSorting.DISTANCE) {
            const branchesByName = this.getBranchesSortedByShowName();
            this.setState({sorting: Branches.BranchSorting.ALPHABETICAL, branches: branchesByName});
        } else {
            Branches.getGeolocationIfAvailable(async (pos) => {
                const branchesByDistance = await this.getBranchesSortedByDistance(pos, this.state.branches);
                this.setState({sorting: Branches.BranchSorting.DISTANCE, branches: branchesByDistance});
            });
        }
    };

    getBranchesSortedByShowName = () => {
        const {branches} = this.state;
        for (let i = 0; i < branches.length; i++) {
            branches[i].showName = Branches.getBranchTitle(branches[i]);
        }

        return branches.sort((a,b) => a.showName.localeCompare(b.showName));
    };

    render() {
        const { branches, selectedBranch, loading, showMap, listOnly } = this.state;
        return (
            <Loader loading={loading} fullscreen={false}>
                <div className="viewSelectBranch">
                    {this.context.state.selectedQueue.preSelecetd ?
                        <TopBar
                        title={this.context.state.selectedCompany}
                        />
                        :
                        <TopBar
                        title={this.context.state.selectedCompany}
                        linkBack={"/tramites"}
                        />
                    }   
                    <AppConsumer>
                        {({ actions }) => (
                        <div className="container-body content">
                            {showMap ?
                                <BranchesMap
                                    branches={branches}
                                    selectedBranch={selectedBranch}
                                    branchSelectedEventHandler={this.branchSelectedHandler}
                                    branchSelectedChangeEventHandler={this.branchSelectedChange}
                                    selectBranchHandler={this.selectBranch}
                                    toggleViewHandler={this.toggleMap}
                                />
                            :
                                <BranchesList
                                    branches={branches}
                                    selectedBranch={selectedBranch}
                                    branchSelectedEventHandler={this.branchSelectedHandler}
                                    branchSelectedChangeEventHandler={this.branchSelectedChange}
                                    selectBranchHandler={this.selectBranch}
                                    toggleBranchSortHandler={this.toggleBranchSorting}
                                    branchSorting={this.state.sorting}
                                    toggleViewHandler={(!listOnly) && this.toggleMap}
                                />
                            }
                        </div>
                        )}
                    </AppConsumer>
                </div>
            </Loader>
        );
    }

    static getBranchDireccion(branch) {
        return (branch && branch.direccion && `${branch.direccion}${branch.estado ? ', ' + branch.estado : ''}`) ||
            (branch && branch.mBranch && branch.mBranch.direccion &&
                `${branch.mBranch.direccion}${branch.mBranch.estado ? ', ' + branch.mBranch.estado : ''}`);
    }

    static renderBranchOrMBranchDireccion(branch, t) {
        const direccion = this.getBranchDireccion(branch);
        if (direccion) {
            return Branches.renderBranchInfo(t("Branches.ADDRESS"), direccion);
        }
        return false;
    }

    static getWaitingRoomForQueueInBranch(branch) {
        let res = {};

        if (branch.defaultWaitingRoomIdForQueue && branch.monitorInfo && branch.monitorInfo.waitingRooms) {
            const waitingRoom = branch.monitorInfo.waitingRooms.filter((wr)=> branch.defaultWaitingRoomIdForQueue === wr.id)[0];
            if (waitingRoom) res = waitingRoom;
        }
        return res;
    }

    static getBranchOrMBranchAverageWaitingTime(branch, mBranch) {
        const waitingRoom = this.getWaitingRoomForQueueInBranch(branch);
        let monitorInfo;

        if (waitingRoom && waitingRoom.avgWaitingTime) {
            monitorInfo = waitingRoom;
        } else if (branch && branch.monitorInfo && branch.monitorInfo.avgWaitingTime) {
            monitorInfo = branch.monitorInfo;
        } else if (mBranch && mBranch.monitorInfo && mBranch.monitorInfo.avgWaitingTime){
            monitorInfo = mBranch.monitorInfo;
        } else {
            return false;
        }

        return !isNaN(parseFloat(monitorInfo.avgWaitingTime)) && `${anchorTime(monitorInfo.avgWaitingTime)}`;
    }

    static renderBranchOrMBranchAverageWaitingTimeIfDefined(branch, mBranch, t) {
        const waitingTime = this.getBranchOrMBranchAverageWaitingTime(branch, mBranch);
        if (waitingTime) {
            return Branches.renderBranchInfo(t("Branches.WAIT_TIME"), waitingTime);
        }
        return false;
    }

    static renderBranchOrMBranchWaitingTurnsIfDefined(branch, mBranch, t) {
        const waitingTurns = this.getBranchOrMBranchWaitingTurns(branch, mBranch);
        if (waitingTurns) {
            return Branches.renderBranchInfo(t("Branches.TURNS_AHEAD"), waitingTurns);
        }
        return false;
    }

    static getBranchOrMBranchWaitingTurns(branch, mBranch) {
        const waitingRoom = this.getWaitingRoomForQueueInBranch(branch);

        let monitorInfo;

        if (waitingRoom && waitingRoom.waitingTurns) {
            monitorInfo = waitingRoom;
        } else if (branch && branch.monitorInfo && branch.monitorInfo.waitingTurns) {
            monitorInfo = branch.monitorInfo;
        } else if (mBranch && mBranch.monitorInfo && mBranch.monitorInfo.waitingTurns){
            monitorInfo = mBranch.monitorInfo;
        } else {
            return false;
        }

        return !isNaN(parseFloat(monitorInfo.waitingTurns)) && monitorInfo.waitingTurns;
    }
}

Branches.contextType = AppConsumer;
export default withRouter(Branches);

